@import "./variables";
@import "./mixins";

.success{
    max-width: 100%;
    height: 100%;
    display: flex;
    padding: 7rem 12rem;
    align-items: center;
    justify-content: center;
    background: yellow;
    background-image:
    radial-gradient(
      circle at top right,
      $color-main,
      $color-secondary 100%
    );
    @include respond(tablet){
        padding: 3rem 8rem;
    }
    @include respond(smartPhone){
        padding: 1rem ;
    }

    &-container{
        width: 100%;
        height: 100%;
        border-radius: 7px;
        overflow: hidden;
        background-color: white;
        padding: 2rem;
    }
    &-header{
        border-bottom: 1px solid rgba($color-secondary, .3);
    }
    &-heading{
        color: $color-secondary;
        font-size: 22px;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        text-transform: uppercase;
    }
    &-message{
        
        padding: 0 .3rem;
        font-size: 14px;
        line-height: 1.58rem;
        font-family: Arial, Helvetica, sans-serif;
        color: #4d5156;
        margin-top: 2rem;
    }
}