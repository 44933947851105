@import "./variables";
@import "./mixins";

.nav{
    display: grid;
    grid-template-columns: 1fr .2fr 1fr;
    padding: .5rem 3.5rem;
    justify-content: space-between;
    color: $color-secondary;
    font-family: "Roboto", 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 11px;
    @include respond(tablet){
        display: flex
    }
    @include respond(tablet){
        padding: .5rem 1rem;
    }
    
    &-contact{
        display: flex;
        & > *:not(:first-child) {
            margin-left: 1.5rem;
        }
        @include respond(smartPhone){
            display: none;
        }
    }
    &-social{
        display: flex;
        justify-content: space-around;
        & > *:hover{
            cursor: pointer;
        }
        @include respond(tablet){
            display: none;
        }
    }
    &-navigation{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        @include respond(tablet){
           margin-left: auto;
        }
    }
    &-mobile{
        display: flex;
        align-items: center;
    }
    &-email{
        display: flex;
        align-items: center;
    }
    &-text{
        margin-left: .3rem;
        & >a, a:link, a:active {
            color: $color-secondary;
        }
    }
    &-icon{
        width: 1.1rem;
        height: 1.1rem;
        &:hover{
            cursor: pointer;
        }
    }
    &-link, &-link:link, &-link:active{
        margin-right: 1.5rem;
        font-weight: 700;
        color: $color-secondary;
    }
}