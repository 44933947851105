@import "./variables";

%btn{
    padding: .5rem 1rem;
        text-transform: uppercase;
        border-radius: 50px;
        outline: none;
        &:hover{
            cursor: pointer;
        }
}
.btn{

    &-main{
        @extend %btn;
        color: white;
        background-color: $color-secondary;
        font-family: "Roboto", 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    }
    &-secondary{
        @extend %btn;
        color: $color-secondary;
        background-color: $color-main;
    }
}