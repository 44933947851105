@import "./variables";
@import "./mixins";
.loader {
    display: inline-block;
    width: 100%;
    min-height : 9.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    
    &:after {
        content: " ";
        display: block;
        width: 4rem;
        height: 4rem;
        margin: 1px;
        border-radius: 50%;
        border: 10px solid #fff;
        border-color: $color-main rgba($color-secondary,1) $color-main;
        animation: loader 1.2s linear infinite;
    }
  }
  
  
  @keyframes loader {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  