@import "./variables";
@import "./mixins";
.browse{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 3.5rem 0;
    place-items: center;
    color: $color-secondary;
    &-slider{
        display: grid;
        align-items: center;
        justify-items: center;
        padding: 2.5rem;
        grid-template-columns: repeat(auto-fit, minmax(15rem,1fr));
        gap: 1.5rem 2rem;
        width: 78%;
        @include respond(tablet){
            width: 85%;
            gap: .7rem;
            padding:  1rem .28rem;
        }
        @include respond(smartPhone){
            width: 100%;
            grid-template-columns: repeat(auto-fit, minmax(14rem, 1fr));
            padding:  1rem .28rem;
        }
        @include respond(phone){
            width: 100%;
            grid-template-columns: 1fr;
            gap: 1rem;
            padding:  1rem .28rem;
        }
    }
    &-heading{
        text-transform: uppercase ;
        font-size: 20px;
        font-weight: 700;
        font-family: "Roboto", Arial, Helvetica, sans-serif;
        @include respond(smartPhone){
            font-size: 16px;
        }
    }
}
.control{
    &-container{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 15%;
        padding: 1rem;;
    }
    &-arrow{
        width: 2rem;
        height: 2rem;
        border : .3px solid rgba($color-main, .5);
        padding: .3rem;
        border-radius: 5px;
        &:hover{
            cursor: pointer;
        }
        &--left{
            transform: rotate(90deg);
        }
        &--right{
            transform: rotate(-90deg);
        }
    }
}