@import "./variables";
@import "./mixins";

.topview{
    background: linear-gradient(to top, $color-main 0% 10%, white 60% 100%);
    min-height: 100vh;
    padding-bottom: 1.5rem;
    display: flex;
    flex-direction: column;
    position: relative;
    @include respond(laptop){
        min-height: 60vh;
    }
    &-gridContainer{
        height: 100%;
        display: grid;
        grid-template-columns: .6fr 1fr .6fr;
        margin: auto 0;
        @include respond(tablet){
            display: flex;
            flex-direction: column;
        }
    }
    &-flank{
        padding: 4rem 0 0 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        @include respond(tablet){
            padding: 0;
        }
        
    }
    &-middle{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1rem 0;
        padding-top: .2rem;
        & > *:first-child{
            transform: translateX(25px);
            @include respond(laptop){
                transform: translateX(0);
            }
        }
        @include respond(laptop){
            padding-top: 1.5rem;
        }

    }
    &-phrase{
        text-transform: uppercase;
        font-weight: 700;
    }
    

}
.toplogo{
    &-container{
        flex : 0 0 20%;
        display: flex;
        justify-content: center;
        padding: 1rem;
        padding-bottom: 0rem;

    }
    &-logo{
        width: 6rem;
        @include respond(laptop){
            width: 7rem;
        }
    }

}

.payment{
    &-container{
        padding: 1.5rem 0;
        width: 60%;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        border-bottom: 2px solid $color-secondary;
        @include respond(tablet){
            padding: 0;
            padding-bottom: 1rem;
            width: 100%;
        }
    }
    &-icon{
        width : 60%;
        height: 2rem;
        @include respond(tablet){
            width: 80%;
            height: 1.5rem;
        }
    }
    &-brands{
        width: 90%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding-top: .6rem;
        
        &--logo{
            
            width: 35%;
            height: 3rem;
            
        }
        @include respond(tablet){
            width: 100%;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-63%);
            padding: 0 .5rem;
            justify-content: space-between;
        }
        
    }
    &-hide{
        &-show{
            display: none;
            @include respond(tablet){
                display: block;
                margin: .5rem 0;
                padding-left: .88rem;
                width: 80%;
            }
        }
    }
}
.borderBottom{
    width: 60%;
    bottom: 0;
    position: absolute;
    left : 20%;
    border-bottom: 2px solid $color-secondary;
}
.top{
    &-text{
        padding: 1.5rem 0 1rem 0;
        text-align: center;
        color: $color-secondary;
        font-size: 15.6px;
        font-weight: 700;
        @include respond(tablet){
            width: 70%;
            text-align: none;
            font-size: 13px;
        }
        
    }
    &-image{
        max-width: 95%;
        min-width: 60%;
       
    }
    &-flag{
       
        width: 2rem;
    }
    &-info{
        position: relative;
        z-index: 1;
    }
}
.topViewHide{
    @include respond(tablet){
        opacity: 0;
        position: absolute;
        z-index: -3;
    }
}