@import "./variables";
@import "./mixins";

.pagination{
    width: 40%;
    display: flex;
    height: 3rem;
    align-items: center;
    justify-content: space-around;
    font-size: 18px;
    font-weight: 900;
    color: $color-secondary;
    @include respond(tablet){
        width: 70%;
    }
}
.page{
    &-number{
        &:hover{
            cursor: pointer;
        }
        &--active {
            border-bottom: 2px solid $color-main;
            color: $color-main;
        }
    }
    &-icon{
        font-size: 22px;
        &:hover{
            cursor: pointer;
        }
    }
    &-disabled{
        color: $color-grey-light;
        &:hover{
            cursor: default;
        }
    }
}