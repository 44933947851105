@import "./variables";
@import "./mixins";
.checkout{

    max-width: 100%;
    position: relative;
    height: 95%;
    display: flex;
    padding: 3.5rem 6rem;
    align-items: center;
    justify-content: center;
    background: yellow;
    background-image:
    radial-gradient(
      circle at top right,
      $color-main,
      $color-secondary 100%
    );
    @include respond(tablet){
      padding: 2.3rem 2.5rem;
    }
    
    &-container{
        width: 100%;

        height: 100%;
        border-radius: 7px;
        overflow: hidden;
        display: grid;
        grid-template-columns: .22fr 1fr .45fr;
        background-color: white;
        position: relative;
        z-index: 2;
        @include respond(tablet){
          grid-template-columns: .15fr .7fr .36fr;
        }
        @include respond(potrait){
          grid-template-columns: 1fr;
          grid-template-areas: "first"
                                "second"
                                "third";
          overflow-y: scroll ;
        }
    }
}
.checkForm{
  &-container{
    padding: 2rem 1rem;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: .4em;
    }
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      box-shadow: inset 0 0 6px rgba(0,0,0,0.3);;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba($color-secondary,0.6);
      outline: 1px solid slategrey;
    }
    @include respond(potrait){
      overflow-y: initial;
      grid-area: third;
    }
  }
}