@import "./variables";
@import "./mixins";

.card{
    min-height: 17rem;
    min-width: 15rem;
    width: 75%;
    height: 100%;
    max-width: 15rem;
    padding: .3rem;
    border : 1.4px solid $color-main;
    color: $color-secondary;
    border-radius: 5px;
    display : flex;
    flex-direction: column;
    align-items: center;
    font-family: "Roboto Mono";
    box-shadow:  0px 3px 8px rgba($color-secondary, .4);
    position: relative;
    @include respond(tablet){
        max-width: 20rem;
        min-height: 20rem;
        width: 85%;
    }
    
    &-container{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    &-cartLabel{
        background-color: rgba($color-secondary, .8);
        color: white;
        border-radius: 50%;
        width: 2.8rem;
        height: 2.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 0;
        transform: translate(50%, 20%);
        top: 0;
        box-shadow: 0px 0px 9px rgba($color-secondary, .6);
        text-transform: uppercase;
        font-weight: 700;
        font-size: 9px;
        @include respond(smartPhone){
            transform: translate(0, 0);
            border-radius: 3px;
            height: auto;
            right: .3rem;
            top: .2rem;
            padding: .2rem ;
        }
        &-quantity{
            top: 3.5rem;
            width: 2.4rem;
            height: 2.4rem;
            @include respond(smartPhone){
                width: 2rem;
                height: 2rem;
            }
           
        }
        &:hover{
            cursor: pointer;
        }
    }
    &-text{
            text-align: center;
            text-transform: uppercase;
            color: $color-secondary;
         
    }
    &-header{
        font-size: 11px;
        @include respond(smartPhone){
            font-size: 10.5px;
        }
    }
    &-size{
        font-size: 11px;
        @include respond(smartPhone){
            font-size: 10.5px;
        }
    }
    &-title{
        font-size: 11px;
        font-weight: 700;
        @include respond(smartPhone){
            font-size: 10.5px;
        }
    }
    &-image{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
       
        
        &--png{
            max-height: 100%;
            max-width: 100%;
            @include respond(phone){
                max-height: 85%;
                max-width: 85%;
            }
        }
    }
    &-control{
        align-self: stretch;
        display: flex;
        justify-content: space-between;
        align-items: center;
      

    }
    &-price{
        font-family: "Roboto Mono";
        font-size: 18px;
        font-weight: 900;
        @include respond(smartPhone){
            
            font-weight: 700;
        }
    }

    &:empty{
        position: relative;
        background-image: linear-gradient(to right, rgb(224, 224, 199), rgba(255, 255, 255, 0.466)),
                        radial-gradient(circle 20px, rgb(253, 253, 201) 80%, transparent),
                        linear-gradient(to right, rgb(248, 253, 220), rgb(252, 255, 235)),
                        linear-gradient(to right,rgb(255, 255, 255), #ececd0);
        background-size : 10rem 2rem, 5rem 5rem,8rem 1.6rem, 100% 100%;
        background-position: .8rem 10%, 10% 30%,2rem 80%, 0 0;
        background-repeat: no-repeat;
        &:before{
            content: "";
            height: .3rem;
            width: 100%;
            position: absolute;
            bottom: 5px;
            left: 0;
            background: linear-gradient(to left, $color-main 0, $color-secondary);
            background-size: 200% 200%;
            animation : loadingGradient 1s infinite ;
        }
        &:after{
            content: "";
            height: .3rem;
            width: 100%;
            position: absolute;
            top: 5px;
            left: 0;
            background: linear-gradient(to left, $color-main 0, $color-secondary);
            background-size: 200% 200%;
            animation : loadingGradient 1s infinite ;
        }
    }
    &-hidden{
        opacity: 0;
    }
    &-info{
        font-family: "Roboto Mono";
        width: 85%;
        bottom: 0;
        left: 50%;
    
        border-radius:0px 0px 8px 8px;
        border : 1.4px solid $color-main;
        border-top: none;
        padding :.4rem 1.3rem;
        padding-top: 1.1rem;
    }
    &-cart{
        padding: .5rem 0;
    }
    &-sizes{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 50%;
        padding: 0 .1rem;
        position: absolute;
        right: 1rem;
        bottom: .5rem;
        min-height: 4rem;
        &--select{
            border : 1px solid $color-secondary;
            border-radius: 50%;
            background-color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 2rem;
            height: 2rem;
            cursor: pointer;
            &:hover{
                box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 
                0px 4px 5px 0px rgba(0,0,0,0.14), 
                0px 1px 10px 0px rgba(0,0,0,0.12);
                background-color: #d5d5d5;
            }
            &-active{
                border : 2px solid $color-main;
                background-color: rgba($color-secondary, 1) ;
                color: white;
                transform: scale(1.5);
                &:hover{
                    background-color: $color-secondary;
                }
            }
        }
        &--text{
            font-weight: 700;
            font-size: 12px;
        }
        
    }


}

.cart{
    &-btn{
        position: relative;
        display: flex;
        align-items: center;
        padding: .23rem .35rem;
        border-radius: 3px;
        font-family: "Roboto Mono";
        font-size: 9px;
        color: $color-secondary;
        box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2),
                    0px 2px 2px 0px rgba(0,0,0,0.14),
                    0px 1px 5px 0px rgba(0,0,0,0.12);
        background-color: #eaedf2;
        transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        &:hover{
            cursor: pointer;
            box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 
                        0px 4px 5px 0px rgba(0,0,0,0.14), 
                        0px 1px 10px 0px rgba(0,0,0,0.12);
            background-color: #d5d5d5;
        }
        &:active{
            box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px 
                        rgba(0,0,0,0.14), 
                        0px 3px 14px 2px rgba(0,0,0,0.12);

        }
        &:disabled{
            color: #a8a5a5;
            box-shadow: none;
            & > *{
                opacity: 0%;
                
            }
            &::before{
                content: "0 LEFT!";
                position: absolute;
                top: 50% ;
                font-size: 1.1em;
                left:  50%;
                transform: translate(-50%, -50%);
                width: 4rem;
                color: red;
                 z-index: 100;
            }
        }
       
    }
    &-icon{
        height: 1rem;
        width: 1rem;
        margin-left: .5rem;
    }
    &-add{
        font-size: 11px;
        font-weight: 700;
        text-transform: uppercase;
    }
}
@keyframes loadingGradient {
    0%{
        background-position: 0% 50%;
    }
    100%{
        background-position: -200% 0%;
    }
}   
.hover{
    &-card{
        position: absolute;
        left: 0;
        top: 0;
        background-color: rgba($color-main, .5);
        
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        &--stock{
            background-color: rgba($color-secondary, .2);
        }
    }
    &-info{
        padding: .6rem .8rem;
        border-radius: 4px;
        color: white;
        text-transform: uppercase;
        background-color: rgba($color-secondary, .8);
        font-family: "Roboto",sans-serif;
        border : 1.4px solid $color-main;
        &:hover{
            cursor: pointer;
        }
    }
    &-price{
        width: 6rem;
        height: 6rem;
        border-radius: 50%;
        background-color: rgba($color-secondary,1);
        display: flex;
        position: absolute;
        bottom: 0;
        left: 0;
        transform: translate(-40%,50%);
        justify-content: center;
        align-items: center;
        color: white;
        font-size: 21px;
        font-weight: 700;

        border : 2px solid $color-main;
       @include respond(tablet){
           width: 5rem ;
           height: 5rem;
       }
       @include respond(smartPhone){
           width : 4rem;
           height: 4rem;;
       }
        &-label{
            margin-right: .1rem ;
            font-size: 24px;
            transform: translateY(-.1rem);
        }
    }
}