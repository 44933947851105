@import "./variables";
@import "./mixins";

.top-info{
    background-color: $color-secondary;
    display: flex;
    flex-direction: column;
    padding:  1.1rem 1rem;
    align-items: stretch;
    color: $color-main;
    text-transform: uppercase;
    border-radius: 3px;
    font-family: "Roboto", 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    @include respond(tablet){
        display: none
    }
}
.info{
    &-heading{
        padding: 0 0 .6rem 0;
        font-weight: 700;
        letter-spacing: .08rem;
        font-size: 14px;
        text-align: center;
        border-bottom: .5px solid $color-main;
        @include respond(laptop){
            font-size: 12px;
        }
    }
    &-text{
        font-size: 11px;
        letter-spacing: .06rem;
        @include respond(laptop){
            font-size: 9px;
        }
    }
    &-group{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: .5rem 0;
        & > p {
            color : $color-grey;
        }
       
    }
    &-terms{
        padding: 1rem 0 0 0;
        font-size: 8px;
        border-top: .5px solid $color-main;
        text-align: center;
    }
}