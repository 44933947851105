@import "./variables";
@import "./mixins";

.breadcrumb{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 4rem 0;
    background-color: $color-breadcrumb;
    @include respond(potrait){
        grid-area: first;
        flex-direction: row;
        padding: .7rem;
        justify-content: center;
        align-content: space-around;

    }
    
    &-circle{
        color: $color-grey-dark;
        font-family: "Roboto mono", sans-serif;
        font-weight: 700;
        font-size: 20px;
        background-color: $color-grey-light;
        border-radius: 50%;
        width: 3.3rem;
        height: 3.3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all .5s ease-in;
        @include respond(tablet){
            font-size: 17px;
        }
        @include respond(potrait){
            font-size: 16px;
            width: 2.8rem;
            height: 2.8rem;

        }
        @include respond(smartPhone){
            font-size: 13px;
            width: 2rem;
            height: 2rem;

        }
    }
    &-text{
        color: $color-grey-dark;
        text-align: center;
        text-transform: uppercase;
        font-weight: 700;
        font-family: "Roboto Mono";
        letter-spacing: .05rem;
        padding: .23rem;
    }
    &-rule{
        background-color: $color-main;
        width: 100%;
        height: 3px;
        &--container{
            width: 2rem;
            height: 2rem;
            display: flex;
            align-items: center;
            transform: rotate(90deg);
        }
    }

    &-active{
        color: white;
        background-color: $color-secondary;
    }
    &-checked{
        color: white;
        background-color: $color-main;
    }
    &-group{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        @include respond(smartPhone){
            flex-direction: column;
        }
    }
}