@import "./mixins";
@import "./variables";

.shipmentMethod{
    width: 100%;
    padding: 0 1rem;
    @include respond(smartPhone){
        padding: 0 .2rem;
    }

    &-frame{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-top: .5rem;
        min-height: 15rem;
        color: $color-secondary;
        font-weight: 400;

        & > p{
            margin-bottom: 1rem;
        }
    }
    &-item{
        width: 80%;
        padding: 1rem 1.5rem;
        border : .5px solid rgba($color-secondary, .1);
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: $color-bg-light;
        margin-bottom:.9rem;
        @include respond(laptop){
            width: 100%;
        }
        @include respond(smartPhone){
            padding: 1rem .4rem;
        }
    
        

    }
    &-title{
        font-weight: 700;
        word-break: break-word;
        text-align: center;
    }
    &-text{
        text-align: center;
        @include respond(smartPhone){
            text-align: left;
        }
    }
    &-price{
        text-align: right;
    }
    &-icon{
        width: 40px;
        height: 40px;
        background-image: url("../Images/carDelivery.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        @include respond(tablet){
            display: none;
        }
    }
}