@import "./variables";
@import "./mixins";
.category{
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(13rem, 1fr));
        gap: 1.5rem;
        padding: 2.5rem 4rem;
        @include respond(tablet){
            grid-template-columns: repeat(auto-fit, minmax(11.5rem, 1fr));
            gap: 1rem;
            padding:  1rem .28rem;
        }
        @include respond(smartPhone){
            grid-template-columns: repeat(auto-fit, minmax(9.5rem, 1fr));
            gap: .23rem;
            padding:  .5rem .28rem;
            grid-template-areas: 
                                "a b"
                                "c d"
                                "e .";
        }
        @include respond(phone){
            grid-template-columns: 1fr;
            grid-template-areas: 
                                "a"
                                "b"
                                "c"
                                "d"
                                "e";
        }
        
    &-card{
        display : flex;
        flex-direction: column;
        justify-content: center;
        border-radius: 5px;
        overflow: hidden;
        padding: .7rem;
        @include respond(tablet){
            
            padding: .4rem 0.4rem 0 0 ;
        }
        @include respond(smartPhone){
            min-height: 6.5rem;
            position: relative;
        }
        & > a {
                @include respond(smartPhone){
                    position: absolute;
                    right: .4rem;
                    bottom: .5rem;
                }
            & > button {
                @include respond(smartPhone){
                    padding : .25rem 2.5rem;
                    border-radius: 5px;
                }
            }
        }
        
    }
    
    &-card[name="Tops"]{
        background-color: $color-secondary;
        color: $color-main;
        text-shadow: 0px 0px 1px rgba(white, .7);
        @include respond(smartPhone){
            grid-area : a;
        }
        @include respond(smartPhone){
            grid-area : a;
        }
    }
    
    &-card[name="Dresses"]{
        background-color: $color-main;
        color: white;
        text-shadow: 0px 0px 2px rgba(black, .7);
        @include respond(smartPhone){
            grid-area : b;
        }
    }
    &-card[name="Accessories"]{
        background-color: $color-secondary;
        color: $color-main;
        text-shadow: 0px 0px 1px rgba(black, .7);
        @include respond(smartPhone){
            grid-area : c;
        }
    }
    &-card[name="Bottoms"]{
        background-color: $color-main;
        color: white;
        text-shadow: 0px 0px 2px rgba(black, .7);
        @include respond(smartPhone){
            grid-area : d;
        }
    }
    &-card[name="Winterwear"]{
        background-color: $color-secondary;
        color: $color-main;
        text-shadow: 0px 0px 1px rgba(black, .7);
        @include respond(smartPhone){
            grid-area : e;
        }
    }
    
    &-text{
        text-align: right;
        margin-bottom: 1rem;
        font-family: "Roboto", 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-size: 12px;
        line-height: 1.2rem;
        font-weight: 400;
        @include respond(tablet){
            
            font-size: 11px;
            line-height: 1rem;
        }
        @include respond(smartPhone){
            
            font-size: 10.6px;
            line-height: .9rem;
        }
        @include respond(phone){
            
            font-size: 12.6px;
            line-height: .9rem;
        }
        
    }
    &-heading{
        font-family: "Roboto", 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 20px;
        margin-bottom: 1rem;
        @include respond(smartPhone){
            margin-bottom: .5rem ;
            font-size: 16px;
        }
    }

}