@import "./variables";
@import "./mixins";

.order{
    background-color: $color-breadcrumb;
    color: $color-secondary;
    font-family: "Roboto Mono", 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    display: flex;
    height: 100%;
    flex-direction: column;
    @include respond(potrait){
        grid-area: second;
    }
    &-nav{
        width: 100%;
        padding:  1rem;
        border-bottom: 1px solid rgba($color-grey, .6);
        position: relative;
        
        box-shadow: 0px 2px 12px rgba($color-grey, .4);

    }
    &-heading{
        font-size: 16px;
    }
    &-close{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 1rem;
        font-size: 26px;
        width: 1.5rem;
        height: 1.5rem;
        z-index: 5;
        transition: all .2s ease-in;
        &:hover{
            cursor: pointer;
        }
    }
    &-ref{
        height: 100%;
        display: flex;
        flex-direction: column;
        place-content: center;
        align-items: center;
        padding: .5rem;
        padding-bottom: 1rem;
         transition: height .3s ;
         overflow: hidden;
         opacity: 1;
         transition: height .3s ease-in-out;
        
        &--active{
            max-height: 0px;
            height: 0px;
            opacity: 0;
            
           
        }
    }
    &-page{
        padding: .8rem;
        height: 16rem;
        width: 100%;
        padding-top: 0%;
        overflow-y: scroll;
        border-bottom: 1px solid rgba($color-grey, .4);
        box-shadow: 0px 2px 12px rgba($color-grey, .4);
        opacity: 1;
        &::-webkit-scrollbar {
            display: none;
        }
        @include respond(potrait){
            height: 100%;
            max-height: 100%;
        }
        
    }
    &-row{
        border-bottom: 1px solid rgba($color-grey, .6);
        display: flex;
        justify-content: flex-start;
        padding: 0.4rem .4rem .4rem 0;
    }
    &-image{
        width: 2.7rem;
        height: 2.6rem;
        margin-right: .4rem;
        & > img {
            width: 100%;
            height: 100%;
        }
    }
    &-text{
        flex: 1;
    }
    &-price{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
        align-items: flex-end;
    }
    &-totals{
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 1rem .8rem;
        @include respond(potrait){
            padding: .4rem .9rem;
        }
    }
    &-subtotals{
        
        padding: 0rem .3rem .14rem 0;
        display: flex;
        align-items: center; 
        justify-content: space-between;
    }
    &-btn{
        font-weight: 900;
        font-family: "Roboto", sans-serif;
        padding: .6rem .6rem;
        color: white;
        border-radius: 5px;
        background-color: $color-secondary;
        border: 2px solid $color-main;
        margin:0 auto;
    }
}
.item{
    &-heading{
        font-weight: 700;
        font-size: 9px;
    }
    &-price{
        font-weight: 700;
        font-size: 12px;
    }
    &-quantity{
        padding: .2rem .5rem;
        border-radius: 5px;
        border: 1px solid rgba($color-grey, .5);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &-remove{
        font-size: 10px;
        font-weight: 900;
        color: rgb(231, 142, 110);
        &:hover{
            cursor: pointer;
            background-color: trans;
        }
    }
}

.total{
    &-text{
        font-size: 13px;
    }
    &-price{
        font-size: 13px;
    }
}
.summary-totals{
    padding: 0rem .3rem .4rem 0;
        display: flex;
        align-items: center; 
        justify-content: space-between;
        
    & > * {
        font-weight: 900;
        font-size: 16px;
    }
}
.sidebar{
    &-empty{
        width: 100%;
        height: 65%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: .8rem;
        font-weight: 900;
        text-transform: uppercase;

    }
}
.accordion{
    &-rotate{
        transition: all .2s ease-in;
        transform: translateY(-50%) rotate(-90deg);
    }
}