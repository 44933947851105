$color-main : #DBBA07;
$color-secondary : #5b4e03;
$color-grey-light : #d6d6d3;
$color-grey :  #b3b3b3;
$color-grey-dark : #747472;
$color-breadcrumb : #f3f3ea;
$color-main-light : #e2ddbbd3;
$color-tertiary : #b3b3b3; //grey
$valentinePink : #FF42Ae;
$valentinePink-light : #F984ef;
$valentineRed : #B40800;
$valentineRed-light : #C21912;
$valentineGreen : #BDF4F1;
$valentineBlue : #27fdf5;
$valentinePeach : #f6cfca;
$valentinePurple :#751390 ;
$valentinePurple-light : #941B75;
