@import "./variables";
@import "./mixins";
.footer{
    flex: 1;
    width: 100%;
    background-color: $color-secondary;
    position: relative;
    &-content{
        display: grid;
        grid-template-columns: 1fr 2fr 1fr ;
        padding: 2rem;
        @include respond(smartPhone){
            grid-template-columns: 1fr ;
        }
    }
    color : $color-main;
    &-copy{
        background-color: $color-main;
        color: $color-secondary;
        text-align: center;
    }
}
.image{
    &-container{
       padding: 1.5rem 0 0  0;
       display: flex;
       justify-content: center;
    }
}
.flank{
    border-top: 1px solid $color-main;
    padding: 3rem 0;
    line-height: 1.5rem;
    text-align: center;
    &-link, &-link:active,&-link:link{
        color: $color-main;
    }
    @include respond(smartPhone){
        display: none;
    }
}
.middle{
    display: flex;
    flex-direction: column;
    align-items: center;

}
.social{
    width: 35%;
    display: flex;
    justify-content: space-around;
    position: relative;
    z-index: 1;
    &-links{
        text-decoration: none;
        
    }
    &-icon{
        width:2rem;
        height: 2em;
    }
}
