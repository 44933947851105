@import "./variables";

.shipment{
    display: flex;
    flex-direction: column;
    align-items: center;
    
    height: 100%;

    &-heading{
        align-self: flex-start;
        color: $color-secondary;
        font-family: "Roboto Mono";
        padding : 0 0rem 1rem 1rem;
    }
    &-form{
        display: flex;
        flex-direction: column;
        align-items: stretch;
        width: 93%;
        padding-bottom: 2rem;

    }
}
.details{
    &-row{
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(11rem,1fr)) ;
        column-gap: 1rem;
    }
    &-input{
        
        & textarea:focus ~ label,
        & textarea:active ~ label,
        & textarea:valid ~ label
        {
            transition:  .3s ease-in-out;
            top : 0;
            transform: translateY(-50%);
            color: $color-grey-dark;
            background-color: white;
            left: 10px;
            padding :0 2px;

        }
    }
    
}
