@import "./variables";
.error-p {
    color: #bf1650;
    font-size: 11.2px;
    padding-top: .23rem;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    &::before {
        display: inline;
        content: "⚠ ";
    }
  }
  
  