@import "./variables";
@import "./mixins";

.modal{
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba($color-secondary, .6);
    width: 100%;
    height: 100vh;
    z-index: 100;
    display: flex;
    place-items: center;
    place-content: center;
    font-family: "Roboto", 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 14px;

    &-container{
        width: 62%;
        height: 400px;
        background-color: #fff;
        display: flex;
        @include respond(tablet){
            width: 90%;
            height: 380px;
        }
        @include respond(smartPhone){
            width: 100%;
        }
    }
    &-image{
        flex : 0 0 40%;
        display: flex;
        place-content: center;
        place-items: center;
        
        &-frame{
            border: 1px solid rgba($color-secondary, .3);
            box-shadow: 0px 2px 10px rgba($color-secondary, .6);
            position: relative;
            width: 85%;
            height: 80%;
            display: flex;
            place-content: center;
            place-items: center;
            @include respond(tablet){
                width: 100%;
                height: 100%;
            }
            & > img {
                max-height: 150%;
                max-width: 120%;
                filter: drop-shadow(2px 10px 14px rgb(80, 76, 68));
                @include respond(tablet){
                    max-width: 90%;
                    max-height: 90%;
                }
            }
        }
        
    }
    &-info{
       flex : 1;
       padding: .8rem;
       display: flex;
       flex-direction: column;
       justify-content: stretch ;
       @include respond(smartPhone){
           padding: .5rem .7rem;
       }
    }
    &-header{
        height: 2rem;
        position: relative;
        & > i {
            color: $color-secondary;
            font-size: 28px;
            position: absolute;
            top: 0rem;
            right: 0rem;
            @include respond(tablet){
                font-size: 22px;
            }
            &:hover{
                cursor: pointer;
            }
        }
    }
    &-heading{
        font-size: 18px;
        width: 80%;
        text-transform: uppercase;
        font-weight: 700;
        color: rgba($color-secondary, .8);
        @include respond(tablet){
            font-size: 13px;
            width: 95%;
        }
    }
    &-price{
        color: $color-secondary;
        font-size: 48px;
        font-weight: 200;
        @include respond(tablet){
            font-size: 28px;
        }
    }
    &-text{
        border-top: 1px solid rgba($color-secondary, .4) ;
        border-bottom: 1px solid rgba($color-secondary, .4);
        padding: 1rem 0;
        min-height: 12rem;
        font-size: 12px;
        color: #000000c0;
        @include respond(tablet){
            font-size: 11.58px;
        }

    }
    &-stock{
        color: green;
        font-weight: 700;
        &--out{
            color: red;
            font-weight: 700;
        }

    }
    &-control{
        display: flex;
        place-content: center;
        place-items: center;
        flex: 1;
        & > i {
            font-size: 2rem ;
            color: rgba($color-secondary, .85);
            @include respond(tablet){
                font-size: 1.4rem;
            }
            &:hover{
                cursor: pointer;
            }
        }
    }
    &-btn{
        border-radius: 3px;
        padding: .8rem .6rem;
        background-color: rgba($color-secondary, .85);
        font-size: 15px;
        color: white;
        font-family: "Roboto", sans-serif;
        font-weight: 200;
        margin: 0 1rem;
        @include respond(tablet){
            font-size: 12.4px;
            padding: .6rem .4rem;
            margin: 0 .7rem;
        }
        & > span {
            margin : 0 .5rem
        }
        &:hover{
            cursor: pointer;
        }
    }
    &-label{
        padding: .5rem .8rem;
        font-size: 11px;
        color: white;
        background-color: rgba($color-secondary, .85);
        border-radius: 18px;
        text-transform: uppercase;
        font-family: "Roboto", 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        position: absolute;
        right: .5rem;
        top: .5rem;
        z-index: 30;
        &:hover{
            cursor: pointer;
        }
    }
    &-sizes{
        display: flex;
        padding: .8rem;
        width: 60%;
        margin: 0 auto;
        min-height: 4rem;
        justify-content: space-between;
        align-items: center;
        &--heading{
            font-weight: 700;
            font-size: 1.2em;
            text-align: center;
            margin-top: 1rem;
        }
        &--select{
            border: 2px solid $color-secondary;
            color : $color-secondary;
            background-color: #fff;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 4rem;
            height: 2rem;
            cursor: pointer;
            &-active{
                background-color: rgba($color-secondary, .8);
                color: white;
                border: 3px solid $color-main;
                height: 2.5rem;
                width: 4rem;
            }
        }
    }
}