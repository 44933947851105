@import "./mixins";
@import "./variables";

%common{
        background-color: $color-secondary;
        border: 7px solid white;
        position: relative;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 4px 6px 2px rgba(0,0,0,0.2);
        &:hover{
            cursor: pointer;
        }
}
.cartButton{
    
    &-total{
        @extend %common;
        width: 6rem;
        height: 6rem;
        position: fixed;
        top: 5rem;
        right: 2rem;
        z-index: 5;
        overflow: hidden;
        
    }
    &-cart{
        @extend %common;
    }
    &-quantity{
        @extend %common;
    }
}
.nav-icon{
    &--label{
        font-weight: 700;
        font-size: 11.3px;
        color: white;
        height: 100%;
        padding: .7rem 0;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

    }
    &--display{
        width: 2.4rem;
        
        height: 1rem;
        text-align: center;
        
    }
}