@import "./variables";
@import "./mixins";
.payment{
    display: flex;
    flex-direction: column;
    align-items: center;
    
    height: 100%;

    &-heading{
        align-self: flex-start;
        color: $color-secondary;
        font-family: "Roboto Mono";
        padding : 0 0rem 1rem 1rem;
    }

    &-form{
        display: flex;
        flex-direction: column;
        align-items: stretch;
        width: 93%;
        padding-bottom: 2rem;

    }
}
.method{
    &-group{
        display: flex;
        padding-bottom: 1rem;
        justify-content: center;

    }
    &-container{
        border: 1px solid rgba($color-grey, .4);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: .8rem .5rem;
        margin-right: 2rem;
        border-radius: 5px;
        position: relative;
        @include respond(phone){
            margin-right: .4rem;
        }

    }
    &-provider{
        width: 6rem;
        height: 3rem;

    }
    &-radio{
        width: 100%;
        &:hover{
            cursor: pointer;;
        }
    }
}
.details{
    &-group{
        
        
    }
    &-input{
        margin-bottom: 1.5rem;
        position: relative;

        & input:focus ~ label,
        & input:active ~ label,
        & input:valid ~ label
        {
            transition:  .3s ease-in-out;
            top : 0;
            transform: translateY(-50%);
            color: $color-grey-dark;
            background-color: white;
            left: 10px;
            padding :0 2px;

        }
    }
}
.formLabel{
    position: absolute;
    left: 0;
    padding-left: 10px;
    font-family: "Roboto Mono", sans-serif;
    font-size: 11px;
    font-weight: 700;
    color: $color-grey;
    top: 50%;
    transform: translateY(-85%);
}
.formInput{
    height: 2.2rem;
    width: 100%;
    border: none;
    border: 2px solid $color-grey;
    border-radius: 4px;
    padding-left: 10px;
    font-family: "Roboto Mono", sans-serif;
    font-size: 13px;
    font-weight: 700;
    line-height: 1.58rem;
    color: #4d5156;
    background-color: $color-breadcrumb;
    &:focus,
    &:active,
    &:valid{
        border: 2px solid $color-secondary;
        background-color: white;
    }
    
    &:-webkit-autofill,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:valid
    {
        border: 2px solid $color-secondary;
        -webkit-text-fill-color: #4d5156;
        -webkit-box-shadow: 0 0 0 30px white inset
    }

}

.button{
    &-group{
        align-self: stretch;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }


}
.form{
    &-back{
        background-color: transparent;
        font-size: 14px;
        font-family: "Roboto Mono", 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
        font-weight: 700;
        text-transform: uppercase;
        color: rgb(54, 54, 54);
        @include respond(potrait){
            margin-bottom: .5rem;
        }
        &-text{
            padding-right: .5rem;
            
            font-size: 18px;
            &:hover{
                cursor: default;
            }
        }
        &:hover{
            cursor: pointer;
        }
    }
    &-next{
        padding: .5rem 1.6rem;
        border-radius: 5px;
        background-color: $color-secondary;
        color: white;
        text-transform: uppercase;
        font-size: 14px;
        font-family: "Roboto Mono", 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
        font-weight: 700;
        &:hover{
            cursor: pointer;
        }
    }

}
