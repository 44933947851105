@import "./variables";
@import "./mixins";

.review{
    &-heading{
        align-self: flex-start;
        color: $color-secondary;
        font-family: "Roboto Mono";
        padding : 0 0rem .4rem .5rem;
    }
    &-field{
        padding : .05rem 0;
        font-family: "Roboto";
        font-size: 12px;
        letter-spacing: .05rem;
    }
    &-container{
        padding: 0 1rem;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(11rem, 1fr));
        color: $color-secondary;
        
        gap: 1rem;
    }   
    &-payment{
        width: 100%;
        margin-top: 1rem;
        

    }
    &-summary{ 
        padding: 0 1rem 1rem 1rem;
        width: 100%;
        display: flex;
        flex-direction: column;
        &-item{
            padding: .1rem 0;
            font-size: 13px;
            width: 60%;
            line-height: 1.58rem;
            color: #4d5156;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: rgba(black,.7);
            font-weight: 700;

        }
        &-price{
            padding-right:2.5rem ;
        }   
        &-total{
            padding: .4rem 0;
           
            font-size: 14px;
            font-weight: 700;
            width: 60%;
            display: flex;
            align-items: center;
            color: rgba(black,.7);
            text-transform: uppercase;

            justify-content: space-between;


        }
        &-shipping{
            font-size: 14px;
            padding: .4rem 0;
            font-weight: 700;
            width: 60%;
            display: flex;
            align-items: center;
            color: rgba(black,.7);
            text-transform: uppercase;
            justify-content: space-between;
            border-top: 1px solid rgba($color-secondary,.3) ;
            padding-bottom: 0;
        }
    }
}
.payment{
    &-select{
        margin-left: 1rem;
        width: 40%;
        @include respond(potrait){
            width: 100%;
            margin-bottom: .5rem;
        }
    }
    &-image{
        max-width: 9rem;
        height: 4rem;
    }
    &-notice{
        font-size: 11px;;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-style: italic;
        color: rgba($color-secondary,.9);
        
    }
    &-order{
        width: 100%;
        padding: 3rem 1rem;
        padding-right: 3rem;
        display: flex;
        justify-content: space-between;
        
        @include respond(potrait){
            padding: .8rem;
        }
    }
}
.back-paypal{
    
}