@import "./variables";

.sidebar{
    &-container{
        min-width: 20rem;
        height: 100%;
        
    }
    &-nav{
        background-color: $color-breadcrumb;
        padding: 2rem 1rem;
        display: flex;
        justify-content: center;
        text-transform: uppercase;
        color: $color-secondary;
        font-family: "Roboto";
        position: relative;
        border-bottom: 1px solid rgba($color-secondary, .2);
        & > i {
            font-size: 24px;
            position: absolute;
            left: 1.3rem;
            top: 1rem;
            &:hover{
                cursor: pointer;
            }
        }
    }
    &-content{
        padding: 1rem 1.3rem;
        padding-top: .4rem;
        height: 55%;
        overflow-y: scroll;
        
        color: $color-secondary;
        &::-webkit-scrollbar{
            display: none;
        }
        
    }
    &-row{
        border-bottom: 1px solid rgba($color-grey, .6);
        display: flex;
        justify-content: flex-start;
        padding: .5rem .4rem .4rem 0;
    }
    &-image{
        width: 3rem;
        height: 3.2rem;
        margin-right: .6rem;
        & > img {
            width: 100%;
            height: 100%;
        }
    }
    &-text{
        font-size: 13px;
        font-family: "Roboto";
    }
    &-controls{
        margin-left: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        & > i {
            font-size: 16px;
            &:hover{
                cursor: pointer;
            }
        }
    }
    &-footer{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-items: center;
    }
    &-total{
        font-size: 17px;
        font-weight: 900;
        font-family: "Roboto", sans-serif;
        color: $color-secondary;
    }
    &-checkout{
        font-weight: 900;
        font-family: "Roboto", sans-serif;
        padding: .8rem 1.2rem;
        color: white;
        border-radius: 5px;
        background-color: $color-secondary;
        border: 2px solid $color-main;
        margin-top: 1rem;
        &:hover{
            cursor: pointer;
        }
        & > i {
            margin-right: .5rem;
        }
    }
    
}
.cart{
    &-heading{
        font-weight: 400;
        font-size: 13px;
    }
    &-text{
        font-weight: 700;
    }
    &-price{
       font-weight: 700;
       
    }
}
.control{
    &-price{
        font-size: 13px;
        font-weight: 700;
        font-family: "Roboto mono";
    }
}
.cartUP{
        opacity: 30%;
        pointer-events: none;
}