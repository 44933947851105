$borderPosY : 4%;
$borderPosX : 4%;
$borderWidth : 5px;
$borderRadius : 8px;
$redWidth : 7rem;

.valentine{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top : 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba($valentinePink, .8) ;
    z-index: 10;
    @supports(background-image : conic-gradient(red, yellow)){
        background-color:unset ;
         background-image:conic-gradient( 
						from 301.1deg, rgba($valentinePink-light,.7), rgba($valentinePink-light,1) 180.2deg, 
						rgba($valentinePeach,.9) 180.2deg, rgba($valentinePeach,.9) 285deg, 
						rgba($valentineGreen,.9) 285deg, rgba($valentineGreen,.9) 315deg);
    }
    
    &-card{
        
        position: relative;
        width: 35%;
        min-width: 30rem;
        height: 75%;
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(56, 55, 55, 0.13);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 4rem 0;
        padding-top: 3.5rem;
       

        &:before,
        &::after,
        &>:last-child:before,
        &>:last-child:after{
            position: absolute;
            width: 25%; 
            height: 20%;
            border-color: $valentinePurple-light; 
            border-style: solid;
            
            content: ' ';
            transition: all .3s ease-in-out .2s;
            
        }

        &:before{
            top : $borderPosY;
            left: $borderPosX;
            border-width: $borderWidth 0 0 $borderWidth;
            border-radius: $borderRadius 0 0 0;
        }
        &:after{
            top : $borderPosY;
            right: $borderPosX;
            border-width: $borderWidth $borderWidth 0 0;
            border-radius: 0 $borderRadius 0 0;
        }
        &>:last-child:before{
            bottom: $borderPosY;
            right: $borderPosX;
            border-width: 0px $borderWidth $borderWidth 0;
            border-radius:0 0 $borderRadius 0;
        }
        &>:last-child:after{
            bottom: $borderPosY;
            left: $borderPosX;
            border-width: 0px 0px $borderWidth $borderWidth;
            border-radius:0 0 0 $borderRadius;
        }

        &:hover::before,
        &:hover::after
        {
            width: calc(25% + 25%) ;
            height: 0%;
            border-radius: 0px;
        }
        &:hover >:last-child:after,
        &:hover >:last-child:before{
            width: calc(25% + 25%) ;
            height: 0%;
            border-radius: 0px;
        }

    
        @include respond(tablet){
            min-width: 0;
            width: 75%;
        }
        @include respond(smartPhone){
            width: 90%;
            height: 70%;
        }
    }
    &-heading{
        font-family: 'Oswald', sans-serif;
        font-size: 2.6em;
        font-weight: 700;
        text-transform: uppercase;
        color: $valentinePurple;
       
        
    }
    &-title{
        font-family: 'Tangerine', cursive;
        font-weight: 700;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 6.5rem;
        margin: auto 0;
        @include respond(tablet){
            font-size: 6rem;
        }
        @include respond(smartPhone){
            font-size: 4rem;
        }
        
        
    }
    &-word{
        
        line-height: 1;
    }
    &-sale{
        font-family: 'Oswald', sans-serif;
        text-transform: uppercase;
        font-size: 2em;
    }
    &-off{
        color: $valentinePurple;
        font-weight: 700;
        font-size: 2em;
    }
    &-btn{
        
        margin-top: 1rem;
        text-transform: uppercase;
        font-family: 'Oswald', sans-serif;
        font-size: 1.7em;
        color: whitesmoke;
        background-color: $valentinePurple-light;
        border: 2px solid $valentinePurple;
        font-weight: 400;
        padding: .2rem 1.6rem;
        border-radius: 4px;
        transition: all .2s ease-in-out;
        &:hover{
            cursor: pointer;
            background-color: #fff;
            color: $valentinePurple;
        }

    }
    & > i {
        position: absolute;
        top: 5%;
        right: 5%;
        font-size: 5em;
        color: $valentinePurple;
        transition: all .3s ease-in-out;
        &:hover{
            transform: scale(1.1);
            cursor: pointer;
            color: $valentineRed;
        }
    }
   
   
    
}
.heart{
    &-outside{
        position: absolute;
        width: 7rem;
        height: 7rem;
        z-index: -1;
        @include respond(smartPhone){
            width: 4rem;
            height: 4rem;
            z-index: -1 !important;
        }
        
    }
    &-number{
        &--0{
            top: 0;
            left: 0;
            transform: translate(-40%,-45%) rotateZ(-20deg);
            animation: redScale0 4s ease-out 2s infinite ;
        }
        &--1{
            width: 12rem;
            height: 12rem;
            top: 14%;
            left: 0;
            transform: translateX(-60%) rotateZ(-8deg); 
            @include respond(smartPhone){
                display : none;
            }   
        }
        &--2{
            width: 4rem;
            height: 4rem;
            top: 65%;
            left: 0;
            transform: translateX(-195%) rotateZ(-50deg); 
            @include respond(smartPhone){
                display : none;
            }  
        }
        &--3{
            width: 10rem;
            height: 10rem;
            bottom: 0;
            left: 0;            
            transform: translate(-22%,34%) rotateZ(-30deg); 
            animation: pinkScale3 1s linear 1s infinite alternate;
            z-index: 1;
        }
        &--4{
            bottom: 0;
            right: 0;
            transform: translate(52%,65%) rotateZ(12deg);
            @include respond(smartPhone){
                display : none;
            } 
        }
        &--5{
            width: 4rem;
            height: 4rem;
            top: 65%;
            right: 0;
            transform: translateX(220%) rotateZ(12deg); 
            animation: redScale5 .6s ease-out 5s infinite alternate;
        }
        &--6{
            width: 10rem;
            height: 10rem;
            top: 25%;
            right: 0;
            transform: translateX(50%) rotateZ(17deg);
        }
        &--7{
            z-index: 10;
            width: 6rem;
            height: 6rem;
            top: 53%;
            left: 0;
            transform: translateX(-35%) rotateZ(2deg);
        }
        &--8{
            z-index: 10;
            width: 6rem;
            height: 6rem;
            top: 65%;
            right: 0;
            transform: translateX(25%) rotateZ(-30deg);
        }
        &--9{
            z-index: 10;
            width: 10rem;
            height: 10rem;
            top: 0;
            right: 0;
            transform: translate(35%,-35%) rotateZ(10deg);
            animation: pinkScale9 2s linear 3s infinite alternate;
        }
    }
}
@keyframes redScale0 {
    0%   { transform: translate(-40%,-45%) rotateZ(-20deg) scale(1) }
    25%  { transform: translate(-40%,-45%) rotateZ(-20deg) scale(1.5) }
    75%  { transform: translate(-40%,-45%) rotateZ(-20deg) scale(1)}
    100% { transform: translate(-40%,-45%) rotateZ(-20deg) scale(1.2)}
}
@keyframes redScale5 {
    0%   { transform:translateX(220%) rotateZ(12deg) scale(1) }
    25%  {transform:translateX(220%) rotateZ(12deg) scale(1.5) }
    75%  { transform: translateX(220%) rotateZ(12deg) scale(1)}
    100% { transform: translateX(220%) rotateZ(12deg) scale(1.2)}
}
@keyframes pinkScale3{
    0%   { transform: translate(-22%,34%) rotateZ(-30deg) scale(.9) }
    25%  {transform: translate(-22%,34%) rotateZ(-30deg) scale(.8) }
    75%  { transform: translate(-22%,34%) rotateZ(-30deg) scale(.85)}
    100% { transform: translate(-22%,34%) rotateZ(-30deg) scale(.8)}
}
@keyframes pinkScale9{
    0%   { transform: translate(35%,-45%) rotateZ(30deg) scale(1) }
    25%  {transform: translate(35%,-45%) rotateZ(30deg) scale(.9) }
    75%  { transform: translate(35%,-45%) rotateZ(30deg) scale(1)}
    100% { transform: translate(35%,-45%) rotateZ(30deg) scale(.9)}
}

