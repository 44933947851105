@font-face {
  font-family: 'Segoe UI Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Segoe UI Regular'), url('./Fonts/SegoeUI.woff') format('woff');
 }
 @font-face {
  font-family: 'Segoe UI Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Segoe UI Bold'), url('./Fonts/SegoeUIBold.woff') format('woff');
  }
  

* { 
  /* Remove padding and margin */ 
    margin: 0; 
    padding: 0; 
    border: 0; 
    outline: none;
    
}
*,*::before, *::after {
  box-sizing: border-box;
}
*:disabled{
  pointer-events: none;
  position: relative;
  
}

body {
  font: 62.5%/1.5 "Lucida Grande", "Lucida Sans", Tahoma, Verdana, sans-serif;
  width: 100%;
  padding: 0;

}
button{
  outline: none;
}

p {
  margin : 0;
}

ul {
  list-style: none;

}

a:link , a:visited{
  text-decoration: none;
}

#root{
    height: 100vh;
    
}
.App{
  width : 100%;
  min-height: 100% ;
}